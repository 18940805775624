import { idToken } from '~/store/credentials';
import { profile } from '~/store/profile';

export function getLoadRequest(
  assetId: string,
  isLive = false,
  {
    playSource = '',
    senderName = '',
    senderVersion = '',
  }: {
    playSource?: string;
    senderName?: string;
    senderVersion?: string;
  } = {},
) {
  const loadRequest = new cast.framework.messages.LoadRequestData();

  loadRequest.media = new cast.framework.messages.MediaInformation();
  loadRequest.media.customData = {
    isLive,
    assetId,
    selectedProfile: { id: profile()!.id },
    forgerockTokensData: { idToken: idToken()! },
    playSource,
    senderName,
    senderVersion,
  };
  loadRequest.autoplay = true;

  return loadRequest;
}
